.body-container {
  margin: 0 5%;
  background-color: #252525;
  border-top: 3px solid #00a3cd;
}

.body-wrapper {
  align-items: stretch;
}

.me {
  padding: 20px;
}
