.contactList {
  list-style-type: none;
  padding: 0;
  float: left;
  font-size: 24px;
  width: 300px;
  margin: 0;
  text-align: left;
}

.contactLink {
  padding: 5px 10px 5px 5px;
  transition: all 0.5s ease;
  text-decoration: none;
  color: #b9b9b9;
  display: block;

  &:hover {
    padding-left: 15px;
    transition: padding-left 0.3s ease-out;
  }
}

.linkedin {
  &:hover {
    color: #1a85bc;
  }
}

.twitter {
  &:hover {
    color: #55acee;
  }
}

.facebook {
  &:hover {
    color: #3f5d9a;
  }
}

.github {
  &:hover {
    color: #fff;
  }
}
